import { Controller } from "@hotwired/stimulus"
import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import List from "@editorjs/list"
import Paragraph from "@editorjs/paragraph"
import Code from "@editorjs/code"
import ContentVariable from "../libs/editorjs_content_variables"

// Connects to data-controller="editorjs"
export default class extends Controller {
  static targets = [ "body", "input" ]

  connect() {
    this.editor = new EditorJS({
      holder: this.bodyTarget,
      data: this.initialContent,
      tools: {
        variable: {
          class: ContentVariable,
        },
        header: {
          class: Header,
          inlineToolbar: true,
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          config: {
            inlineToolbar: true,
          }
        },
        code: {
          class: Code,
        },
      },
      placeholder: "Write something...",
      onChange: this._saveEditorContent.bind(this),
      onReady: () => {
        this._setupEventDispatchers()
        this._saveCaretPosition()
      }
    })
  }

  _setupEventDispatchers() {
    this.bodyTarget.addEventListener("focusin", () => {
      window.dispatchEvent(new Event("editorjs:focus", { bubbles: true }))
    })
  }

  async _saveEditorContent() {
    const content = await this.editor.save()
    this.inputTarget.value = JSON.stringify(content)

    window.dispatchEvent(new Event("editorjs:change", { bubbles: true }))
  }

  _saveCaretPosition() {
    this.bodyTarget.addEventListener('keyup', () => {
      const selection = window.getSelection()

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        this.lastCaretPosition = range.cloneRange()
      }
    })
  }

  get initialContent() {
    return JSON.parse(this.inputTarget.value || "{}")
  }

  get caretPosition() {
    return this.lastCaretPosition
  }
}
