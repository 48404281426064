import { ApplicationController, useDebounce } from 'stimulus-use'

// Connects to data-controller="autosubmit"
export default class extends ApplicationController {
  static debounces = [ "debouncedSubmit" ]

  connect() {
    useDebounce(this)
  }

  submit() {
    this.element.requestSubmit()
  }

  debouncedSubmit() {
    this.submit()
  }
}
