import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["editorjs"]

  switchFocus(event) {
    console.log(event)

    if (event.type === "editorjs:focus") {
      this.focusedInput = "editorjs"
    } else {
      this.focusedInput = event.srcElement
    }
  }

  addVariable(event) {
    const variable = event.target.textContent.trim()
    const wrappedVariable = `{${variable}}`

    if (this.focusedInput === "editorjs") {
      this._addToEditorJS(wrappedVariable)
    } else {
      this._addToNormalInput( this.focusedInput, wrappedVariable)
    }
  }

  _addToEditorJS(variable) {
    const caretPosition = this.editorjsOutlet.caretPosition

    if (caretPosition) {
      const tempElement = document.createElement('div')
      tempElement.innerHTML = variable
      const textNode = tempElement.firstChild

      caretPosition.insertNode(textNode)
      caretPosition.setStartAfter(textNode)
      caretPosition.collapse(true)

      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(caretPosition)

      this.editor.save().then((outputData) => {
        this.inputTarget.value = JSON.stringify(outputData)
        this.saveEditorContent()
      })
    }
  }

  _addToNormalInput(input, variable) {
    input.value = `${input.value} ${variable}`
    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
  }
}
